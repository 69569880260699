.label {
    cursor: pointer;
    margin: 0;
    text-align: center;
    color: darkgrey;

    transition: opacity 0.2s ease-in-out;
}

.label:hover {
    opacity: 0.7;
}
